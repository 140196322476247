.flex {
  display: flex;
}

.header {
  display: flex;
  margin: 2.3rem 0 1.5rem;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.headerTitle {
  font-weight: 700;
  margin-bottom: 0.15rem;
}

.headerSubTitle {
  font-size: 0.8rem;
}

.divider {
  margin: 0 1rem;
}

.middleDivider {
  margin: 0.3rem 1.3rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
}
