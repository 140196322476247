.topBarContainer {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0;
}

.leftTopBar {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.rightTopBar {
  display: flex;
  align-items: center;
  margin-right: 2.125rem;
  font-size: 16px;
}

.divider {
  width: 1px;
  height: 0.625rem;
  background-color: var(--ambient-basePlus50);
  margin: 0 0.9375rem;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
}

.liveClockContainer {
  margin-left: 3.5rem;
}

.leftTopBar > svg:first-child {
  height: 45px;
  width: 105px;
}
.leftTopBar > svg:last-child{
  height: 45px;
  width: 45px;
}