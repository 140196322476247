.root {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-base);
  color: white;
  font-size: 1.875rem;
  font-weight: 600;
  height: 100%;
}

.color {
  border-color: var(--accent-base);
}

.icon > svg {
  display: block;
  height: 56px;
  width: 56px;

}

.textContainer {
  margin-top: 0.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chevron {
  position: absolute;
  right: -48px;
}

.textContainerTitle {
  font-size: 16px;
}