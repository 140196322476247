.root {
  position: relative;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noSiteContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.incidentCardsGrid {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); */
  grid-gap: 1rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.pageSelectControlContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 15px;
}
