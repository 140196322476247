.modal > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  min-width: 23rem;
}

.modalContent {
  padding: 1rem 2rem;
}

.modalTitle {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.borderBottom {
  border-bottom: 1px solid var(--base10);
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.checkboxWithComment {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.radioGroup > label {
  padding-left: 1.875rem;
  padding-right: 3.75rem;
}

.loading {
  z-index: 999;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 16rem;
  height: 0px;
}

.inputField {
  width: 10rem;
}
