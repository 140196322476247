.modal {
  background: rgba(51, 62, 72, 0.5);
}

.modal > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 23rem;
  width: 424px;
  min-height: 397px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background: #ffffff;
  box-sizing: border-box;
}

.modal .modalTitleRoot {
  margin: 0;
  padding: 0;
}

.modalContent {
  padding: 0;
}

.modalTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 24px;
  color: #333e48;
  font-weight: 700;
  margin: 17px 0 0 0;
}

.temperatureContainer {
  padding: 7px;
  padding: 27px;
  margin-bottom: 30px;
  min-height: 105px;
}

.tempBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
}

.tempBox .icons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 48%;
}

.tempBox .icons svg {
  height: 39px;
  width: 39px;
}

.tempBox .icons span.valueText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: #45555f;
  margin-left: 10px;
  position: relative;
  top: -3px;
}

.contentDescription {
  background-color: #ff9248;
  min-height: 200px;
}

.contentDescription .contentText {
  font-weight: 400;
  font-size: 22px;
  line-height: 37px;
  color: #ffffff;
  padding: 27px;
  min-height: 112px;
}

.contentDescription .timeAgo {
  height: 44px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0 27px;
}

.contentDescription .timeAgo span {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0.0535714px;
  color: #fffbfb;
  font-style: italic;
}

.normal-background {
  background-color: var(--notification-blue);
}

.medium-background {
  background-color: var(--notification-yellow);
}

.high-background {
  background-color: var(--notification-orange);
}

.highest-background,
.critical-background {
  background-color: var(--notification-red);
}

.dismissed-border-color {
  border-color: var(--dismissed-primary) !important;
}

.dismissed-background-bottom {
  background-color: var(--dismissed-primary) !important;
}

.dismissed-background-top {
  background-color: var(--dismissed-secondary) !important;
}

.dismissed-bottom-text-color {
  color: var(--dismissed-text-color) !important;
}

.dismissed-text-wrap {
  display: inline;
}

.dismissed-text {
  font-weight: 700 !important;
}

.valueText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: #45555f;
}
