.modalTitle {
  margin: 0;
  font-weight: 700;
  padding: 2.3rem 3.25rem 1.5rem 3.25rem;
}

.modalContent {
  padding: 0 3.25rem 3.25rem 3.25rem;
}

.modalFooter {
  background: var(--ambient-basePlus90);
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.8rem;
}

.modalFooter ~ div {
  padding-bottom: 0;
  background-color: black;
}

.modalFooter > button {
  width: 8rem;
}
