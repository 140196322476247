html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--accent-base);
  font-weight: bold;
}

.m0 {
  margin: 0;
}

h3 {
  font-size: 0.875rem;
}
