.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.root {
  position: relative;
  height: calc(100vh - var(--top-bar-menu-height) - 1rem);
  padding-top: 1rem;
  display: flex;
  /* display: grid;
  grid-template-columns: 2fr 1fr; */
}

.button {
  background-color: var(--accent-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.incidentCardsContainer {
  border-right: 1px solid var(--ambient-basePlus90);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 86%;
}

.widgetsContainer {
  height: 100%;
  /* padding: 0 0.875rem; */
  display: grid;
  grid-template-rows: 1fr;
  justify-content: center;
  width: 14%;
}
