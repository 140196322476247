.modalRoot {
  min-width: 25.5rem;
}

.modalTitle {
  margin-bottom: 0.15rem;
}

.button {
  width: 8rem;
}

.noPaddingBottom {
  padding-bottom: 0;
}

.contentContainer {
  color: var(--ambient-base);
}

.subTitle {
  font-size: 0.8rem;
  font-weight: 400;
}
