.root {
  padding-top: 1rem;
  zoom: 1.2; /* non-standard, may not worked for some browser, but kiosk only use on safari */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: grid;
  --headerElementWidth: min(21.875rem);
  grid-template-columns: var(--headerElementWidth) 9fr var(--headerElementWidth);
  padding: 0 1.063rem 1.25rem 1.063rem;
}

.backButtonContainer {
  font-size: 0.75rem;
}

.titleContainer {
  font-weight: 700;
  font-size: 1.375rem;
  text-align: center;
  white-space: nowrap;
}

.iconContainer {
  display: flex;
  padding-right: 1rem;
  align-items: center;
}
