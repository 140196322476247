.modalRoot {
  width: 80%;
  max-height: 80%;
}

.modalTitle {
  margin: 0;
}

.modalContent {
  padding: 0 3.25rem 3.25rem 3.25rem;
}

.flex {
  display: flex;
}

.header {
  display: flex;
  flex-direction: column;
  /* position: sticky;
  top: 0;
  background-color: white;
  z-index: 1; */
}

.stickyHeader {
  position: sticky;
  top: -1rem;
  z-index: 1;
  background-color: white;
}

.stickyHeader::before,
.stickyHeader::after {
  content: '';
  display: block;
  height: 1rem;
  position: sticky;
  -webkit-position: sticky;
}

.stickyHeader::before {
  top: 5.25rem;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
}

.stickyHeader::after {
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0;
  z-index: 2;
}

.stickyHeader > div {
  z-index: 3;
  position: sticky;
  top: 0px;
  margin-top: -1rem;
  background: white;
  height: 6.25rem;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.headerTitle {
  font-weight: 700;
  margin-bottom: 0.15rem;
}

.headerSubTitle {
  font-size: 0.8rem;
  font-weight: 400;
}

.divider {
  margin: 0 1rem;
}

.middleDivider {
  margin: 0.3rem 1.3rem;
}

.button {
  z-index: 1;
}
