/* hacky way of removing table top pageSelector */
/* need long-term solution fix in Table component */
/* .cardStyle > div > div:nth-child(1) {
  display: none;
} */

.cardStyle .table thead tr,
.cardStyle .table tr:last-of-type {
  border-bottom: none;
}

.cardStyle .table tbody td {
  padding: 0.45rem;
  vertical-align: middle;
}

.baseFontColor {
  color: #333e48 !important;
}

.cardStyle {
  padding: 1rem 1rem 0 1rem;
  margin: 0 0.75rem;
  overflow-y: hidden;
  /* flex: 1; */
}

.loading {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-weight: bold;
  font-size: 0.875rem;
}

.mediaElement div {
  color: #333e48;
}

.font-normal {
  font-weight: bold;
  color: var(--notification-blue);
}

.font-medium {
  font-weight: bold;
  color: var(--notification-yellow);
}

.font-high {
  font-weight: bold;
  color: var(--notification-orange);
}

.font-highest,
.font-critical {
  font-weight: bold;
  color: var(--notification-red);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
}
