.buttonGroup {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  --size: 2.4rem;
  height: var(--size);
}

.buttonGroup > button {
  height: 100%;
  min-width: var(--size);

  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.42500001192092896px;
}
