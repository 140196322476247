.flexColumn {
  display: flex;
  flex-direction: column;
}

.tempText {
  font-weight: 600;
  font-size: 0.8rem;
}

.italicText {
  font-style: italic;
  font-size: 0.75rem;
}

.commentType,
.tempText,
.italicText,
.date {
  color: rgba(0, 0, 0, 0.85);
}

.comment,
.commentType,
.date {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}

.comment {
  color: #333e48;
  overflow-wrap: anywhere;
}

.italicText {
  margin-top: 0.35rem;
}

.comment:empty:before {
  content: '\00a0';
}

.table tr {
  border-top-width: 0px;
}

.table th {
  border-top-width: 0px;
}
