.modalRoot {
  --xSpace: 2.65rem;
}

.modalContent {
  padding: 0 var(--xSpace);
}

.modalFooter {
  margin-top: 0.5rem !important;
}

.modalTitle {
  padding: 1.65rem var(--xSpace) 0 var(--xSpace);
  display: flex;
  justify-content: space-between;
}

.textButton {
  padding: 0;
}
