.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0 0.5rem;
}

.label {
  font-size: 0.8rem;
  font-weight: 600;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.forgotPwd {
  text-align: center;
  font-size: 0.75rem;
  color: var(--accent-base);
  cursor: pointer;
}
