.crossBtn {
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  min-width: 0;
  line-height: 18px;
  color: var(--accent-base);
}

.crossBtn:hover {
  background-color: transparent;
  margin: 0;
  border: 0;
}

.crossBtn:disabled {
  background-color: transparent;
  cursor: inherit;
  opacity: 0.5;
  color: var(--ambient-basePlus25);
}

.crossIcon {
}
