.header {
  grid-area: header;
  align-self: end;
}

.message {
  grid-area: message;
  font-size: 0.875rem;
  font-weight: 400;
}

.alertIcon {
  grid-area: alertIcon;
  color: #ff9248;
}

.root {
  display: grid;
  grid-template-areas:
    'alertIcon header'
    '. message';
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.8rem;
  padding: 2rem 2rem 0 2rem !important;
}
