.root {
  width: 31.8%;
  max-width: 268px;
  max-height: initial;
  overflow: visible;
  height: 300px;
  position: relative;
}

.root>.clickAbleBox {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
  z-index: 8;
}