.root {
  height: calc(100vh - 2.5rem - var(--top-bar-menu-height));
  margin: 0 2.5rem;
}

.card {
  padding: 2rem;
  display: grid;
  grid-template-columns: 6rem 1fr 1fr 1fr;
}

.label {
  margin-right: 2rem;
}

.switchButton {
  margin-left: 2rem;
}

.homeButton {
  justify-self: end;
  white-space: nowrap;
}
