.button {
  white-space: nowrap;
  padding: 0.5rem;
  gap: 0.5rem;
  width: 7.875rem;
}

.done {
  background-color: #52c662;
  color: white;
}

.not_done {
  color: var(--accent-base);
}
